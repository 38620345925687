import axios from "axios";

const baseURL = process.env.NEXT_PUBLIC_APP_URL || "http://localhost:3000";

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  config => {
    // Disable for now because of getSession is not working yet
    // const token = getCookie("token");

    // if (token) {
    //   if (!config.headers) {
    //     config.headers = {};
    //   }

    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/** @deprecated */
export default axiosInstance;
