import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { DialogContent as Content } from "../Dialog";
import { cn } from "~/utils/cn";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

export { DialogClose } from "@radix-ui/react-dialog";
export {
  Dialog,
  DialogTrigger,
  DialogDescription,
  DialogTitle,
  DialogFooter,
  DialogHeader,
} from "../Dialog";

export const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    hideClose?: boolean;
    overlayClassName?: string;
  }
>(({ children, className, overlayClassName, ...props }, ref) => {
  return (
    <Content
      aria-describedby={undefined}
      hideClose
      overlayClassName={cn("bg-[#000D3D26]", overlayClassName)}
      className={cn(
        "bottom-0 top-auto flex max-h-[90vh] translate-y-0 flex-col overflow-hidden rounded-t-xl border-none md:bottom-auto md:top-[50%] md:translate-y-[-50%]",
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
    </Content>
  );
});

DialogContent.displayName = "DialogContent";
