import { Job } from "@prisma/client";
import { z } from "zod";
import axios from "~/services/axios.service";
import { jobOpeningUpdateSchema } from "~/services/jobOpenings.types";

export const updateJob = ({
  jobId,
  data,
}: {
  jobId: number;
  data: z.infer<typeof jobOpeningUpdateSchema>;
}): Promise<Job | string> =>
  axios
    .put(`/api/job_openings/${jobId}`, data)
    .then(response => response.data)
    .catch(e => {
      throw e.response.data.message;
    });
