import {
  PAGE_QUANTITY_TO_ADD,
  INITIAL_HEAD_COUNT,
  INITIAL_TAIL_COUNT,
  INITIAL_BODY_COUNT,
} from "./pagination.constants";
import { PageButtonPositionEnum } from "./pagination.enums";

export const calculateHeadCount = (
  page: number,
  maxSegment: number,
): number => {
  return page === maxSegment ? maxSegment + PAGE_QUANTITY_TO_ADD : maxSegment;
};

export const calculateTailCount = (
  page: number,
  endRange: number,
  maxSegment: number,
): number => {
  return page === endRange + PAGE_QUANTITY_TO_ADD
    ? maxSegment + PAGE_QUANTITY_TO_ADD
    : maxSegment;
};

export const createRangeArray = (count: number): number[] => {
  return Array.from({ length: count }, () => 0);
};

export const calculatePagination = (
  totalPages: number,
  maxSegment: number,
  maxRange: number,
  page: number,
) => {
  const endRange = totalPages - maxSegment;
  const atBeginning = page <= maxSegment;
  const atEnding = page > endRange;
  const isTotalPagesLessThanMaxRange = totalPages <= maxRange;
  const isMiddleRange = !atBeginning && !atEnding;

  const headCount = isTotalPagesLessThanMaxRange
    ? totalPages
    : isMiddleRange
      ? INITIAL_HEAD_COUNT
      : calculateHeadCount(page, maxSegment);

  const tailCount = isTotalPagesLessThanMaxRange
    ? INITIAL_TAIL_COUNT
    : isMiddleRange
      ? INITIAL_TAIL_COUNT + 1
      : calculateTailCount(page, endRange, maxSegment);

  const bodyCount = isTotalPagesLessThanMaxRange
    ? INITIAL_BODY_COUNT
    : isMiddleRange
      ? maxSegment
      : INITIAL_BODY_COUNT;

  return {
    head: createRangeArray(headCount),
    tail: createRangeArray(tailCount),
    body: createRangeArray(bodyCount),
    tailCount,
    bodyCount,
  };
};

export const getBodyNumber = (
  index: number,
  page: number,
  maxSegment: number,
) => {
  const position = index + 1;
  const midSegment = Math.floor(maxSegment / 2);

  const isBeginningPosition = position < maxSegment / 2;
  const isEndingPosition = position > Math.ceil(maxSegment / 2);

  return isBeginningPosition
    ? page - (midSegment - index)
    : isEndingPosition
      ? page + (index - midSegment)
      : page;
};

export const getCurrentPage = (
  slice: PageButtonPositionEnum,
  index: number,
  totalPages: number,
  page: number,
  tailCount: number,
  maxSegment: number,
) => {
  switch (slice) {
    case PageButtonPositionEnum.HEAD:
      return index + 1;
    case PageButtonPositionEnum.TAIL:
      return totalPages - (tailCount - (index + 1));
    case PageButtonPositionEnum.BODY:
      return getBodyNumber(index, page, maxSegment);
    default:
      return 1; // default page
  }
};
