import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { tv, type VariantProps } from "tailwind-variants";
import Spinner from "~/components/spinner";
import { cn } from "~/utils/cn";

const buttonVariants = tv({
  base: "inline-flex items-center justify-center rounded-xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:bg-[#999999] [&[role=combobox]]:disabled:bg-white",
  variants: {
    variant: {
      default:
        "bg-primary border-none text-white  hover:bg-primary-text active:bg-actions-pressed disabled:bg-actions-muted",
      primary:
        "border border-primary bg-white hover:bg-primary-background text-primary font-bold active:bg-primary-background-hover",
      outline:
        "border border-primary-1-90 bg-white hover:bg-[#E5F2FF] active:bg-[#D8EBFF] text-primary-1-90 font-bold disabled:text-white-80 disabled:border-white-80 disabled:bg-white-95",
      destructive:
        "bg-white text-destructive-hover font-semibold hover:bg-destructive-background active:bg-destructive-background-2",
      secondary: "bg-secondary text-black shadow-sm hover:bg-secondary/80",
      ghost:
        "hover:bg-accent-2-60 active:bg-accent-2-70 hover:text-accent-foreground disabled:bg-background-muted-background disabled:text-typography-muted",
      "secondary-ghost":
        "hover:bg-background-hover active:bg-background-pressed text-actions-secondary disabled:text-actions-muted disabled:bg-background-muted",
      link: "text-primary-1-90 underline-offset-4 hover:underline active:opacity-[.65]",
      bluey:
        "bg-primary-1-40 hover:bg-primary-1-50 active:bg-primary-1-60 text-primary-1-90",
      truncated_link: "text-primary-1-90 underline-offset-4 hover:underline",
      tonal:
        "bg-primary-background text-typography-color hover:bg-primary-background-hover active:bg-primary-background-pressed disabled:bg-background-muted-background disabled:text-typography-muted",
    },
    size: {
      default: "h-9 px-4 py-2",
      sm: "h-8 rounded-md px-3 text-xs",
      lg: "h-10 rounded-xl px-8",
      icon: "h-9 w-9 rounded-full",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, isLoading = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={!asChild ? cn(buttonVariants({ variant, size, className })) : undefined}
        ref={ref}
        {...props}
        disabled={isLoading || props.disabled}
      >
        <>
          {" "}
          {isLoading && <Spinner />}
          {props.children}
        </>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
