import { cn } from "~/utils/cn";
import { tv } from "tailwind-variants";

export const buttonVariants = tv({
  base: cn(
    "inline-flex items-center justify-center rounded-md transition-colors",
    "focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-1",
    "disabled:pointer-events-none",
  ),
  variants: {
    variant: {
      default: cn(
        "bg-transparent hover:bg-primary-background-hover",
        "active:bg-primary-background-pressed",
      ),
      active: "bg-primary-background-pressed",
    },
    size: {
      default: "size-8",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});
