import { UserProfile, useUser } from "@auth0/nextjs-auth0/client";
import { Role } from "@prisma/client";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { AvatarType } from "~/queries/fetchAvatar";

interface AppMetadata {
  id?: number;
  role?: Role;
}

type SessionDataStatus = "authenticated" | "unauthenticated";

interface SessionData {
  data: {
    user_name?: string | null;
    user: {
      id?: number;
      name: string;
      email?: string | null;
    };
    profile_image: string;
    role?: Role;
    avatarType: AvatarType;
    //TODO: check the use of the properties below

    //this was added to support pages/company/messages/[id].tsx on line 70.
    email_verification_code?: string;

    //this was added to support pages/company/myProfile.tsx on line 24
    created_by?: string;
  };
  status: SessionDataStatus;
  user?: UserProfile;
  isLoading: boolean;
  error?: Error;
}

export const useMockedSession = () => {
  const { status, data: session } = useSession();

  const isLoading = status === "loading";

  const user = session?.user;
  const [data, setData] = useState<SessionData>();
  const appMetadata = { id: 1, role: Role.JOB_SEEKER };

  useEffect(() => {
    if (isLoading || !user) return;
    setData({
      data: {
        user_name: user?.name,
        user: {
          id: appMetadata.id,
          name: String(appMetadata?.id),
          email: user?.email,
        },
        profile_image: `${process.env.NEXT_PUBLIC_APP_URL}/avatar/${
          appMetadata.id
        }?type=${
          appMetadata?.role === "JOB_SEEKER" ? "jobseeker" : "company_user"
        }`,
        role: appMetadata?.role,
        avatarType:
          appMetadata.role === "JOB_SEEKER"
            ? AvatarType.jobSeeker
            : AvatarType.companyUser,
      },
      status: !!user?.email ? "authenticated" : "unauthenticated",
      isLoading,
    });
  }, [isLoading, user]);

  return { ...data };
};

export const useIsJobSeeker = () => {
  const { data } = useMockedSession();
  return data?.role === "JOB_SEEKER";
};
