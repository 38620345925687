import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { ReactNode, forwardRef, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Button } from "~/components/v2/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/v2/Command";
import { Popover, PopoverTrigger } from "~/components/v2/Popover";
import { ScrollArea } from "~/components/v2/ScrollArea";
import { cn } from "~/utils/cn";
import { BadgeView } from "./components/badge-view";
import { ListView } from "./components/list-view";
import { Option } from "./types";

type ViewType = "list" | "badge";

type Base = {
  emptyTerm: string;
  collisionPadding?: number;
  name: string;
  options: Option[];
  placeholder: string;
  searchPlaceholder: string;
  searchByLabel?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: ReactNode;
  onBlur?: () => void;
  disabledLabel?: string;
  ["aria-label"]?: string;
  onChange?: (value: string) => void;
  viewType?: ViewType;
  dataType?: string;
};

type Multi = {
  multi: true;
  setValue: UseFormSetValue<any>;
  value: string[];
} & Base;

type Single = {
  multi?: false;
  setValue: UseFormSetValue<any>;
  value: string;
} & Base;

export type ICombobox = Multi | Single;

export const Combobox = forwardRef<HTMLButtonElement, ICombobox>(
  (
    {
      emptyTerm,
      multi,
      collisionPadding,
      name,
      options,
      placeholder,
      searchPlaceholder,
      setValue,
      value,
      searchByLabel,
      disabled,
      className,
      icon = <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />,
      onBlur,
      onChange,
      viewType = "list",
      dataType = "candidate",
      ...props
    },
    ref,
  ) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleSelect = (newValue: string) => {
      if (multi) {
        const values = value;
        if (values?.includes(newValue)) {
          setValue(
            name,
            values.filter((v: any) => v !== newValue),
            { shouldValidate: true, shouldDirty: true },
          );
        } else {
          setValue(name, [...(values || []), newValue], {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      } else {
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
        handleOpenChange(false);
      }
    };

    const getLabel = (value: any) => {
      if (multi) {
        const labels = options
          .filter(option => value?.includes(option.value))
          .map(option => option.label);

        return labels.join(", ");
      } else {
        return options.find(option => option.value === value)?.label;
      }
    };

    const removeJob = (removeValue: any) => {
      if (!multi) return;
      const values = value;
      setValue(
        name,
        values.filter((v: any) => v !== removeValue),
        { shouldValidate: true, shouldDirty: true },
      );
    };

    const handleOpenChange = (state: boolean) => {
      if (!state && onBlur) {
        onBlur();
      }
      setOpen(state);
    };

    return (
      <>
        <Popover open={open} onOpenChange={handleOpenChange}>
          <PopoverTrigger asChild>
            <Button
              ref={ref}
              variant="outline"
              role="combobox"
              aria-label={props["aria-label"]}
              disabled={disabled}
              className={cn(
                "justify-between border-borders font-normal text-typography-low-contrast hover:bg-white active:bg-white disabled:opacity-50",
                value && "text-muted-foreground",
                className,
              )}
            >
              {multi ? `Select ${emptyTerm}` : getLabel(value) || placeholder}
              {icon}
            </Button>
          </PopoverTrigger>
          <PopoverPrimitive.Content
            className="text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-popover-trigger rounded-md border bg-white p-0 shadow-md outline-none"
            sideOffset={0}
            collisionPadding={collisionPadding}
          >
            <Command>
              <CommandInput placeholder={searchPlaceholder} className="h-9" />
              <CommandEmpty>No {emptyTerm} found.</CommandEmpty>
              <ScrollArea className="h-[200px]">
                <CommandGroup className="overflow-none">
                  {options?.map(option => (
                    <CommandItem
                      value={searchByLabel ? option.label : option.value}
                      key={option.value}
                      onSelect={() => {
                        onChange
                          ? onChange(option.value)
                          : handleSelect(option.value);
                      }}
                      disabled={option.disabled}
                      className="line-clamp-1 flex justify-between data-[selected=true]:bg-[#F2F5FA]"
                    >
                      <div>{option.label}</div>
                      {option.disabled ? (
                        <div>{option.disabledLabel}</div>
                      ) : (
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            multi
                              ? value?.includes(option.value)
                                ? "opacity-100"
                                : "opacity-0"
                              : option.value === value
                                ? "opacity-100"
                                : "opacity-0",
                          )}
                        />
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </Command>
          </PopoverPrimitive.Content>
        </Popover>

        {multi &&
          value?.map((v: any) => {
            if (viewType === "badge") {
              return (
                <BadgeView
                  key={v}
                  v={v}
                  removeJob={removeJob}
                  options={options}
                />
              );
            }

            return (
              <ListView
                key={v}
                v={v}
                removeJob={removeJob}
                options={options}
                dataType={dataType}
              />
            );
          })}
      </>
    );
  },
);

Combobox.displayName = "Combobox";
