import * as React from "react";
import { cn } from "~/utils/cn";
import { Body, BodySize } from "../typography";
import { ButtonIcon } from "../button-icon";
import { paginationClasses } from "./pagination.styles";
import { PaginationProps } from "./pagination.types";
import { PageButtonPositionEnum } from "./pagination.enums";
import {
  MAX_SEGMENT,
  MAX_RANGE,
  INITIAL_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from "./pagination.constants";
import { calculatePagination, getCurrentPage } from "./pagination.utils";
import { PaginationButton } from ".";

const Pagination: React.FC<PaginationProps> = ({
  page = INITIAL_PAGE,
  totalPages,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  onChangePage,
  className,
  rowsPerPageClassName,
}) => {
  const handleChangePage = (pageNumber: number) => () => {
    onChangePage(pageNumber);
  };

  const { head, tailCount, bodyCount, body, tail } = calculatePagination(
    totalPages,
    MAX_SEGMENT,
    MAX_RANGE,
    page,
  );

  const renderButton = (buttons: any[], slice: PageButtonPositionEnum) =>
    buttons.map((_, index) => {
      const currentPage = getCurrentPage(
        slice,
        index,
        totalPages,
        page,
        tailCount,
        bodyCount,
      );
      const isActivePage = page === currentPage;

      const element = (
        <PaginationButton
          key={`pagination-button-${currentPage}`}
          onClick={handleChangePage(currentPage)}
          disabled={page === currentPage}
          variant={page === currentPage ? "active" : "default"}
          aria-pressed={page === currentPage}
          aria-label={`Go to page ${currentPage}`}
          aria-disabled={page === currentPage}
          page={currentPage}
        />
      );

      return React.cloneElement(element, {
        ...(isActivePage ? { "aria-current": "page" } : {}),
      });
    });

  const renderThreeDots = () => (
    <div className="flex size-8 items-center justify-center">
      <Body size={BodySize.small}>...</Body>
    </div>
  );

  return (
    <nav
      role="navigation"
      aria-label="Pagination Navigation"
      className={cn(paginationClasses.container, className)}
    >
      <Body
        className={cn(
          "text-neutral-tertiary min-w-[50px]",
          rowsPerPageClassName,
        )}
        size={BodySize.small}
        aria-live="polite"
      >
        {rowsPerPage} rows per page
      </Body>

      <div className="flex items-center gap-2">
        {/* <Body
          className="text-neutral-tertiary"
          size={BodySize.small}
          aria-live="polite"
        >
          Page {page} of {totalPages}
        </Body> */}

        <div className="flex">
          <ButtonIcon
            icon="fa-solid fa-chevron-left"
            onClick={handleChangePage(1)}
            disabled={page === 1}
            aria-disabled={page === 1}
            aria-label="Previous page"
            size="medium"
          />

          {renderButton(head, PageButtonPositionEnum.HEAD)}
          {!!bodyCount && renderThreeDots()}
          {renderButton(body, PageButtonPositionEnum.BODY)}
          {!!tailCount && renderThreeDots()}
          {renderButton(tail, PageButtonPositionEnum.TAIL)}

          <ButtonIcon
            icon="fa-solid fa-chevron-right"
            onClick={handleChangePage(page + 1)}
            disabled={page === totalPages}
            aria-disabled={page === totalPages}
            aria-label="Next page"
            size="medium"
          />
        </div>
      </div>
      <div className="w-[90px]"></div>
    </nav>
  );
};

Pagination.displayName = "Pagination";

export { Pagination };
