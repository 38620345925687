import { cn } from "~/utils/cn";

export const paginationClasses = {
  container:
    "relative flex w-full flex-row items-center justify-between gap-2 py-3 px-4 bg-neutral-00",
  text: "items-center inline-flex text-xs leading-[1.125rem] text-typography-low-contrast",
  button: cn(
    "flex items-center justify-center text-typography-high-contrast",
    "bg-transparent rounded-md leading-[1.125rem] w-8 h-8 transition-colors",
    "hover:bg-primary-background-hover active:bg-primary-background-pressed",
    "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
    "disabled:text-icon-neutral-disabled disabled:pointer-events-none disabled:cursor-not-allowed",
  ),
};
