import * as React from "react";
import { VariantProps } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { Body, BodySize } from "~/scalis-components/core/typography";
import { PaginationButtonProps } from "~/scalis-components/core/pagination";
import { buttonVariants } from "./pagination-button.styles";

const PaginationButton: React.FC<
  PaginationButtonProps & VariantProps<typeof buttonVariants>
> = ({ page, className, variant, size, ...props }) => {
  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      <Body size={BodySize.small} asChild>
        <span>{page}</span>
      </Body>
    </button>
  );
};

PaginationButton.displayName = "PaginationButton";

export { PaginationButton, buttonVariants };
