import { Badge } from "../../Badge";
import { Option } from "../types";

export const BadgeView = ({
  v,
  removeJob,
  options,
}: {
  v: any;
  removeJob: (v: any) => void;
  options: Option[];
}) => (
  <div className="flex gap-2">
    <Badge onClick={() => removeJob(v)}>
      <div className="flex cursor-pointer items-center">
        <span className="text-xs">
          {options.find(elem => elem.value === v)?.label}
        </span>
        <i className="mi-close text-xs font-extrabold text-white"></i>
      </div>
    </Badge>
  </div>
);
